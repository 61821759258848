@font-face {
  font-family: "Mono-Book";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/Mono-Book.otf") format("opentype"),
    url("./fonts/MonoTT-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Injurial";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/InjurialTrial-Regular.otf") format("opentype");
}

@mixin default-type-style {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "Helvetica", sans-serif;
  font-weight: bold;
  color: #fff;

  @media (orientation: landscape) and (max-width: 1440px) {
    font-size: 0.685rem;
  }
}

@mixin form-type-style {
  font-size: 1.25rem;
  font-size: clamp(0.85rem, 2.1vh, 1.2rem);
  line-height: 1.5em;
  text-transform: uppercase;
  font-family: "Injurial";
  color: #fff;

  @media (orientation: landscape) and (max-width: 1440px) {
    font-size: 1rem;
  }

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    font-size: 1.25rem;
  }
}

* {
  box-sizing: border-box;
  &:not(input, .signup__input-wrapper) {
    user-select: none;
    -webkit-user-select: none;
  }
}
$mobile-bp: 743px;
html,
body {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  touch-action: none;
  background: #000;
  --footer-bottom: 5%;
}

body {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  //;
  touch-action: none;
  -webkit-touch-callout: none;
}
.phone__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
}
.phone__outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0) scale(1.07);
}
.phone {
  display: none;
  display: none;
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1.24vh;
  transform-origin: top center;
  transform: translate3d(0, 150%, 0) scale(0.58);
  transition: transform 2s ease-out 2s, opacity 2s ease-out 2s;
  @include default-type-style();
  height: 75vh;
  width: auto;
  border-radius: 4.66vh;
  aspect-ratio: 428/880;
  will-change: transform;
  @media screen and (min-width: 1900px) and (min-height: 1000px) {
    transform: translate3d(0, 110vh, 0) scale(0.58);

    height: 66.6vh;
  }

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    aspect-ratio: unset;
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 0;
    transform: none;
    max-height: unset;
  }
}

body.cta-confirmed .phone {
  transform: translate3d(0, 0, 0) scale(1);
  opacity: 1;
}

.phone__inner {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 99;

  border-radius: 4.66vh;
  overflow: hidden;
  backdrop-filter: blur(40px);

  // background: rgba(#000, 0.5);

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    backdrop-filter: none;
    border-radius: 0;
  }
}

.phone__border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
  -webkit-transform: translate3d(0, 0, 0);

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    display: none;
  }
}

.phone__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  display: none;
  z-index: 0;
  opacity: 0;

  @media (orientation: landscape) {
    display: block;
  }
  @media only screen and (min-width: $mobile-bp) {
    display: block;
  }
}

.sticker__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0px, 100vh);
  transition: transform 3.5s ease-out 1s;
}
body.cta-confirmed .sticker__wrapper {
  transform: translate(0px, 0px);
}

.phone__sticker {
  --scale: 1;
  --scale-mobile: 1;
  width: 100%;
  position: absolute;

  transform: translate(-50%, 100vh);
  width: calc(100% - 1rem);
  max-height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.1s ease-out;

  &.visible {
    opacity: 1;
  }

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    transform: translate(-50%, 100vh);
  }

  @media only screen and (max-width: $mobile-bp) {
    object-fit: contain;
    max-height: 60vh;
  }
  // @media (orientation: landscape) {
  //   display: none;
  // }
}

body.cta-confirmed .phone__sticker {
  &.anchor__top {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%) scale(var(--scale));
    transform-origin: top;
  }
  &.anchor__center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(var(--scale));
  }
  &.anchor__bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%) scale(var(--scale));
    transform-origin: bottom;
  }
  @media (orientation: portrait) and (max-width: $mobile-bp) {
    &.anchor__top {
      transform: translate(-50%, 0%) scale(var(--scale-mobile));
    }
    &.anchor__center {
      transform: translate(-50%, -50%) scale(var(--scale-mobile));
    }
    &.anchor__bottom {
      transform: translate(-50%, 0%) scale(var(--scale-mobile));
    }
  }
}

.phone__logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 40%;
  margin: auto;
  top: 7%;
  transform-origin: top center;
  transform: scale(2) translateY(15vh);
  transition: transform 0s ease-out 2s;
  //;
  touch-action: none;
  -webkit-touch-callout: none;

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    top: 1%;
    width: 32%;
    transform: translateY(-150px);
    transition: transform 1s ease-out 3s;
  }
}

body.cta-confirmed .phone__logo {
  transform: none;
}

.phone__status {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6%;
  transform-origin: center;
  transform: translateY(-100%);
  transition: transform 1s ease-out 3s;
  margin-top: 2%;
  padding: 0 3%;

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    display: none;
  }
}

body.cta-confirmed .phone__status {
  transform: none;
  // opacity: 1;
}

.phone__notch {
  height: 70%;
  border-radius: 111px;
  background: #000;
  transition: aspect-ratio 1s ease-out 3s;
  aspect-ratio: 1.5/1;
}

body.cta-confirmed .phone__notch {
  aspect-ratio: 3.5/1;
}
@font-face {
  font-family: "SFSB";
  src: local("SF Pro Text Medium");
  font-weight: 666;
  font-style: normal;
}

@font-face {
  font-family: "HelvetNeueMedium";
  src: local("Helvetica Neue Medium"), local("HelveticaNeue-Medium");
  font-weight: 666;

  font-style: normal;
  size-adjust: 110%;
}

@font-face {
  font-family: "HelvetMedium";
  src: local("Helvetica");
  font-weight: 666;
  font-style: normal;
  size-adjust: 110%;
}

.phone__time {
  position: absolute;
  left: 15%;
  font-variant-numeric: tabular-nums;
  font-family: "SFSB", "HelvetNeueMedium", "HelvetMedium", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.5vh;
  font-weight: 400;

  @media only screen and (min-width: 1900px) {
    font-size: 1.2vh;
  }
  transform-origin: center;
  transform: translateX(-1rem);
  transition: transform 1s ease-out 3s;
}

body.cta-confirmed .phone__time {
  transform: none;
}

.phone__status-icons {
  position: absolute;
  right: 15%;
  height: 28%;
  transform: translateX(1rem);
  transition: transform 1s ease-out 3s;
}

body.cta-confirmed .phone__time {
  transform: none;
}

.phone__footer {
  position: absolute;
  bottom: 0;
  padding-bottom: var(--footer-bottom);
  left: 0;
  width: 100%;
  transform-origin: top center;
  transform: scale(1.15) translateY(100%);
  transition: transform 1s ease-out 3s;
  &:not(.custom-bg) {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(black, 0.8) 100%
    );
  }
  overflow: visible;

  @media (orientation: portrait) and (max-width: $mobile-bp) {
    transition: transform 1.5s ease-out 3s;
    transform: scale(1.15) translateY(150px);
  }

  &.custom-bg {
    background: linear-gradient(
      180deg,
      rgba(0, 71, 111, 0) 0%,
      rgba(0, 71, 111, 0.5) 100%
    );
  }
}

body.cta-confirmed .phone__footer {
  transform: none;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  padding: 0 1%;
}

.phone__footer--signup .buttons {
  opacity: 0;
  pointer-events: none;
}

.buttons__button {
  appearance: none;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
  width: 2rem;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 1s ease-out;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  @media only screen and (max-width: $mobile-bp) {
    cursor: none;
  }
  * {
    pointer-events: none;
  }
  &.sound {
    svg {
      opacity: 0;
    }

    &.mute {
      svg {
        &:nth-child(1) {
          opacity: 1;
        }
      }
      &:hover {
        svg {
          &:nth-child(1) {
            opacity: 0;
          }
        }
        svg {
          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }

    &:not(.mute) {
      svg {
        &:nth-child(1),
        &:nth-child(2) {
          opacity: 0;
        }
      }

      svg {
        &:nth-child(3) {
          opacity: 1;
        }
      }

      &:hover {
        svg {
          &:nth-child(3) {
            opacity: 0;
          }

          &:nth-child(4) {
            opacity: 1;
          }
        }
      }
    }
    // &.mute {
    //   svg {
    //     &:nth-child(2) {
    //       opacity: 0;
    //     }
    //   }
    // }

    // &:not(.sound) {
    //   svg {
    //     &:nth-child(2) {
    //       opacity: 1;
    //     }
    //     &:nth-child(1) {
    //       opacity: 0;
    //     }
    //   }
    // }
  }
}

.buttons__button:active {
  @media only screen and (min-width: 810px) {
    transform: scale(1.4);
  }
}

.buttons__button > svg {
  width: 35%;
  padding: 1px; // ios cropping bug
  height: auto;
  position: absolute;
  will-change: opacity;
  transition: opacity 0.3s ease-out;
}

.buttons__button > svg:nth-child(2) {
  margin: 0 0.75rem;
  // border: 1px solid #fff;
  cursor: pointer;
  appearance: none;
  opacity: 0;
}

.buttons__button {
  @media screen and (min-width: $mobile-bp) {
    &:hover {
      svg:nth-child(1) {
        opacity: 0;
      }
      svg:nth-child(2) {
        opacity: 1;
      }
    }
  }
}

.buttons__switch {
  -webkit-tap-highlight-color: transparent;

  width: 20%;
  height: auto;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  background-color: transparent;
  position: relative;
  border: none;
  overflow: hidden;
  margin: 0 0.75rem;

  cursor: pointer;
  appearance: none;
  transition: transform 0.25s ease-out;
  @media screen and (min-width: 810px) {
    &:hover {
      transform: scale(1.2);
    }
    &:active {
      transform: scale(1.2);
    }
  }
}

.buttons__switch-img {
  --scale: 1;
  position: absolute;
  transform: scale(var(--scale));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  touch-action: none;
  -webkit-touch-callout: none;
  pointer-events: none;
  object-fit: contain;
  display: none;
  &.visible {
    display: block;
  }
}

.outer-fill-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  opacity: 0;
}
.videos__wrapper {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.visible {
    opacity: 1;
  }
}

img[src="null"] {
  display: none;
}

img[src*="-circle"] {
  border-radius: 100%;
}
body.bg-loaded .outer-fill-bg {
  transition: opacity 0.35s ease-out;
  transition-delay: 0.3s;
  opacity: 1;
}
body.bg-loaded.cta-confirmed .outer-fill-bg {
  transition: opacity 0.35s ease-out;
  transition-delay: 0.3s;
  opacity: 1;
}
body.inner-bg-loaded.cta-confirmed .phone__bg {
  transition: opacity 0.8s ease-out;
  transition-delay: 0.3s;
  opacity: 1;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 0;
  overflow: hidden;
  div {
    cursor: pointer;
  }

  opacity: 0;
  transition: opacity 2s ease-out;
  &.cells-hidden {
    & > :not(video) {
      opacity: 0;
    }
  }
}

body.cta-confirmed .wrapper {
  opacity: 1;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // pointer-events: none;
  opacity: 1;
  background-color: black;
  transition: opacity 0.6s ease-in-out 2s;
}

.loading__logo {
  width: 72%;
  max-width: 30rem;
  transition: all 2s ease-in 0.3s;
  margin-bottom: 2.125rem;
  object-fit: contain;
  z-index: 999;
  opacity: 0;
  transition: opacity 0.5s ease-in-out 2s, transform 2s ease-in 0.3s;
  @media (orientation: portrait) and (max-width: $mobile-bp) {
    margin-bottom: 1.7rem;
  }
  &:not(.visible) {
    display: none;
  }
}
body.loop-loaded .loading__logo.visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0s, transform 2s ease-in 0.3s;
}
body.cta-confirmed .loading__logo.visible {
  transition: opacity 1.2s ease-in-out 0.3s, transform 1.2s ease-in 0.3s;

  transform: rotate(120deg) scale(0.5);

  opacity: 0;
}

.loading__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.loader {
  width: 50%;
  flex-shrink: 0;
  height: 5px;
  border-radius: 11px;
  background: rgba(#fff, 0.2);
  transition: opacity 0.2s ease-in-out;
  overflow: hidden;
  z-index: 0;
  --progress: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
body.loop-loaded .loader {
  transition: opacity 0.5s ease-in-out;

  opacity: 1;
}
body.loaded-ready .loader {
  transition: opacity 0.2s ease-in-out;

  opacity: 0;
}

.loader__inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 1)
  );
  content: "";
  transform: scaleX(var(--progress));
  transition: transform 300ms ease-out;
  transform-origin: left;
  // animation: loader-inner 1.5s ease-out;
}

body.cta-confirmed .loader {
  opacity: 0;
}

.static {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  visibility: hidden;
  will-change: visibility;
}

.static video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

.static img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: auto;
  max-width: 18rem;
  transform: translate(-50%, -50%);
}

.signup__submit {
  appearance: none;
  /* remove  css styles from button */
  border: none;
  outline: none;
  padding: 0;
  background: none;
  color: inherit;
  font-family: inherit;
  text-transform: uppercase;
  font-size: inherit;
  border: 1px solid transparent;
  cursor: pointer;
  width: 5ch;
  font-weight: normal;
  text-align: right;
  margin-left: auto;
}

.signup__submit:disabled {
  cursor: not-allowed;
}

.signup {
  @include form-type-style();
  flex-grow: 1;
  display: flex;
  gap: 0.75rem;
  position: relative;
  padding: 0 8%;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
}

.signup__close {
  background: transparent;
  appearance: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-size: 0;
  cursor: pointer;
  * {
    pointer-events: none;
  }
}

.signup__input-wrapper {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  gap: 1rem;
}

@keyframes background-position {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -400% 0;
  }
}

.signup__input-wrapper::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 0, 255, 1) 0%,
    rgba(0, 255, 0, 1) 25%,
    rgba(87, 148, 247, 1) 50%,
    rgba(151, 71, 255, 1) 75%,
    rgba(255, 0, 255, 1) 100%
  );
  background-repeat: repeat-x;
  background-size: 400% auto;
  animation: background-position 2s linear infinite;
}

// .signup__input-wrapper::before {
//   background-image: url("data-url:./images/dashed-line-1.png");
//   animation: dashed-line-2 2s linear infinite;
// }

// .signup__input-wrapper::after {
//   animation: dashed-line 12s linear infinite;
//   background-image: url("data-url:./images/dashed-line-2.png");
// }

.signup__input {
  flex-grow: 1;
  background: none;
  appearance: none;
  outline: none;
  border: none;
  width: 100%;
  border: 1px solid transparent;
  padding: 0;
  font-family: "Injurial";
  @include form-type-style();
  line-height: 1.5em;
  user-select: all !important;
  -webkit-user-select: all !important;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
}

.signup__value {
  opacity: 0;
  overflow: auto;
  font-family: "Injurial";
  @include form-type-style();
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.signup__check-email,
.signup__error {
  display: none;
  border: 1px solid transparent;
  @include form-type-style();
  // font-size: 0.7rem !important;
  font-size: inherit;
}

.signup--success .signup__check-email {
  display: block;
}

.signup--error .signup__error {
  display: block;
}

.signup--success .signup__input,
.signup--error .signup__input {
  display: none;
}

.phone__footer--signup .signup {
  opacity: 1;
  pointer-events: all;
  padding-bottom: var(--footer-bottom);
}

@keyframes bell {
  0% {
    fill: #ff00ff;
  }

  20% {
    fill: #00fff0;
  }

  40% {
    fill: #5794f7;
  }

  60% {
    fill: #9747ff;
  }

  80% {
    fill: #00ff00;
  }
}

.bell-notification {
  animation: bell 5s ease-out infinite;
}

.outer-fill-bg-gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  border: 2px solid green;
}

.loading {
  * {
    // cursor: wait !important;
  }
}
body.loaded-ready .permissions__buttons {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition-delay: 0s;
}
.loading-elements {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 120px;
  overflow: hidden;
  margin-top: -50px;
}
body.cta-confirmed .permissions__buttons {
  opacity: 0;
  transform: translate(-50%, 100%);
}
.permissions__buttons {
  position: absolute;
  display: flex;
  color: white;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  transition: opacity 1s cubic-bezier(0.24, 0.78, 0.35, 1.09) 0.1s,
    transform 1.5s cubic-bezier(0.24, 0.78, 0.35, 1.09) 0.1s;
  & > * + * {
    margin-left: 10px;
  }
  span {
    display: block;
  }
  button {
    appearance: none;
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 2rem;
    border: 1px solid #474747;
    background-color: black;
    color: white;
    font-weight: 300;
    font-size: 12px;
    font-variant-numeric: tabular-nums;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 40px rgba(white, 0.15);

    svg {
      margin-right: 5px;
      transition: all 0.5s ease-in-out;
      pointer-events: none;
      fill: white !important;
      stroke-width: 0;

      @media only screen and (min-width: 1920px) {
        stroke: white;
        stroke-width: 0.5;
      }
    }
    span {
      pointer-events: none;
    }
    @media only screen and (max-width: $mobile-bp) {
      cursor: none;
    }
    &:hover {
      &#audio-off {
        color: #ff005c;
        border-color: #ff005c;
        background-color: rgba(#ff005c, 0.1);
        box-shadow: 0px 0px 40px rgba(#ff005c, 0.33);
        svg {
          fill: #ff005c !important;
          stroke: #ff005c !important;
        }
      }
      &#audio-on {
        color: #05f272;
        border-color: #05f272;
        background-color: rgba(#05f272, 0.1);
        box-shadow: 0px 0px 40px rgba(#05f272, 0.33);
        svg {
          fill: #05f272 !important;
          stroke: #05f272 !important;
        }
      }
    }
    &:active,
    &:focus {
      transition: all 0s ease-in-out;
      svg {
        transition: all 0s ease-in-out;
      }
    }
  }
}
// &:not(.custom-bg) {
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 15%;
//     background: #000;
//     background: linear-gradient(
//       0,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(black, 0.8) 100%
//     );
//   }
// }

// &.custom-bg {
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: #000;
//     background: linear-gradient(
//       0,
//       rgba(38, 145, 0, 0.5) 0%,
//       rgba(132, 238, 255, 0.5) 100%
//     );
//   }
// }
body.custom-bg {
  .phone__inner {
    background: linear-gradient(
      180deg,
      rgba(38, 145, 0, 0.5) 0%,
      rgba(132, 238, 255, 0.5) 100%
    );
  }
  @media only screen and (max-width: $mobile-bp) {
    .outer-fill-bg {
      opacity: 0 !important;
    }
    .wrapper {
      background-color: white;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(38, 145, 0, 0.5) 0%,
          rgba(132, 238, 255, 0.5) 100%
        );
      }
    }
    .phone__inner {
      background: transparent;
    }
  }
}
video {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  &:host {
    display: none !important;
  }
}
video::-webkit-media-controls-container {
  display: none;
}
* {
  -webkit-tap-highlight-color: transparent;
}
