@font-face {
  font-family: Mono-Book;
  font-weight: normal;
  font-style: normal;
  src: url("Mono-Book.b321f433.otf") format("opentype"), url("MonoTT-Book.6e2a7776.ttf") format("truetype");
}

@font-face {
  font-family: Injurial;
  font-weight: normal;
  font-style: normal;
  src: url("InjurialTrial-Regular.bf6ad302.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

:not(input):not(.signup__input-wrapper) {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html, body {
  touch-action: none;
  --footer-bottom: 5%;
  background: #000;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
}

body {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
}

img {
  touch-action: none;
  -webkit-touch-callout: none;
}

.phone__wrapper {
  width: 100%;
  height: 100%;
  perspective: 1500px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.phone__outer {
  width: 100%;
  height: 100%;
  transform: rotate3d(0) scale(1.07);
  justify-content: center;
  align-items: center;
  transition-property: transform, box-shadow;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.phone {
  width: 100%;
  height: 100%;
  transform-origin: top;
  text-transform: uppercase;
  color: #fff;
  height: 75vh;
  width: auto;
  aspect-ratio: 428 / 880;
  will-change: transform;
  border-radius: 4.66vh;
  padding: 1.24vh;
  font-family: Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: bold;
  transition: transform 2s ease-out 2s, opacity 2s ease-out 2s;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 150%, 0)scale(.58);
}

@media (orientation: landscape) and (max-width: 1440px) {
  .phone {
    font-size: .685rem;
  }
}

@media screen and (min-width: 1900px) and (min-height: 1000px) {
  .phone {
    height: 66.6vh;
    transform: translate3d(0, 110vh, 0)scale(.58);
  }
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone {
    aspect-ratio: unset;
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0;
    padding: 0;
    transform: none;
  }
}

body.cta-confirmed .phone {
  opacity: 1;
  transform: translate3d(0, 0, 0)scale(1);
}

.phone__inner {
  width: 100%;
  height: 100%;
  z-index: 99;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 4.66vh;
  position: relative;
  overflow: hidden;
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone__inner {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    border-radius: 0;
  }
}

.phone__border {
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone__border {
    display: none;
  }
}

.phone__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  z-index: 0;
  opacity: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media (orientation: landscape) {
  .phone__bg {
    display: block;
  }
}

@media only screen and (min-width: 743px) {
  .phone__bg {
    display: block;
  }
}

.sticker__wrapper {
  width: 100%;
  height: 100%;
  transition: transform 3.5s ease-out 1s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 100vh);
}

body.cta-confirmed .sticker__wrapper {
  transform: translate(0);
}

.phone__sticker {
  --scale: 1;
  --scale-mobile: 1;
  width: 100%;
  width: calc(100% - 1rem);
  max-height: 100%;
  object-fit: cover;
  pointer-events: none;
  opacity: 0;
  transition: opacity .1s ease-out;
  position: absolute;
  transform: translate(-50%, 100vh);
}

.phone__sticker.visible {
  opacity: 1;
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone__sticker {
    transform: translate(-50%, 100vh);
  }
}

@media only screen and (max-width: 743px) {
  .phone__sticker {
    object-fit: contain;
    max-height: 60vh;
  }
}

body.cta-confirmed .phone__sticker.anchor__top {
  transform: translate(-50%, 0%) scale(var(--scale));
  transform-origin: top;
  top: 0;
  left: 50%;
}

body.cta-confirmed .phone__sticker.anchor__center {
  transform: translate(-50%, -50%) scale(var(--scale));
  top: 50%;
  left: 50%;
}

body.cta-confirmed .phone__sticker.anchor__bottom {
  transform: translate(-50%, 0%) scale(var(--scale));
  transform-origin: bottom;
  bottom: 0;
  left: 50%;
}

@media (orientation: portrait) and (max-width: 743px) {
  body.cta-confirmed .phone__sticker.anchor__top {
    transform: translate(-50%, 0%) scale(var(--scale-mobile));
  }

  body.cta-confirmed .phone__sticker.anchor__center {
    transform: translate(-50%, -50%) scale(var(--scale-mobile));
  }

  body.cta-confirmed .phone__sticker.anchor__bottom {
    transform: translate(-50%, 0%) scale(var(--scale-mobile));
  }
}

.phone__logo {
  width: 40%;
  transform-origin: top;
  touch-action: none;
  -webkit-touch-callout: none;
  margin: auto;
  transition: transform 0s ease-out 2s;
  position: absolute;
  top: 7%;
  left: 0;
  right: 0;
  transform: scale(2)translateY(15vh);
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone__logo {
    width: 32%;
    transition: transform 1s ease-out 3s;
    top: 1%;
    transform: translateY(-150px);
  }
}

body.cta-confirmed .phone__logo {
  transform: none;
}

.phone__status {
  width: 100%;
  height: 6%;
  transform-origin: center;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  padding: 0 3%;
  transition: transform 1s ease-out 3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone__status {
    display: none;
  }
}

body.cta-confirmed .phone__status {
  transform: none;
}

.phone__notch {
  height: 70%;
  aspect-ratio: 1.5 / 1;
  background: #000;
  border-radius: 111px;
  transition: aspect-ratio 1s ease-out 3s;
}

body.cta-confirmed .phone__notch {
  aspect-ratio: 3.5 / 1;
}

@font-face {
  font-family: SFSB;
  src: local(SF Pro Text Medium);
  font-weight: 666;
  font-style: normal;
}

@font-face {
  font-family: HelvetNeueMedium;
  src: local(Helvetica Neue Medium), local(HelveticaNeue-Medium);
  font-weight: 666;
  font-style: normal;
  size-adjust: 110%;
}

@font-face {
  font-family: HelvetMedium;
  src: local(Helvetica);
  font-weight: 666;
  font-style: normal;
  size-adjust: 110%;
}

.phone__time {
  font-variant-numeric: tabular-nums;
  transform-origin: center;
  font-family: SFSB, HelvetNeueMedium, HelvetMedium, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 1.5vh;
  font-weight: 400;
  transition: transform 1s ease-out 3s;
  position: absolute;
  left: 15%;
  transform: translateX(-1rem);
}

@media only screen and (min-width: 1900px) {
  .phone__time {
    font-size: 1.2vh;
  }
}

body.cta-confirmed .phone__time {
  transform: none;
}

.phone__status-icons {
  height: 28%;
  transition: transform 1s ease-out 3s;
  position: absolute;
  right: 15%;
  transform: translateX(1rem);
}

body.cta-confirmed .phone__time {
  transform: none;
}

.phone__footer {
  padding-bottom: var(--footer-bottom);
  width: 100%;
  transform-origin: top;
  transition: transform 1s ease-out 3s;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: visible;
  transform: scale(1.15)translateY(100%);
}

.phone__footer:not(.custom-bg) {
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .8) 100%);
}

@media (orientation: portrait) and (max-width: 743px) {
  .phone__footer {
    transition: transform 1.5s ease-out 3s;
    transform: scale(1.15)translateY(150px);
  }
}

.phone__footer.custom-bg {
  background: linear-gradient(rgba(0, 71, 111, 0) 0%, rgba(0, 71, 111, .5) 100%);
}

body.cta-confirmed .phone__footer {
  transform: none;
}

.buttons {
  justify-content: space-between;
  align-items: stretch;
  padding: 0 1%;
  display: flex;
}

.phone__footer--signup .buttons {
  opacity: 0;
  pointer-events: none;
}

.buttons__button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 2rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: transform 1s ease-out;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 743px) {
  .buttons__button {
    cursor: none;
  }
}

.buttons__button * {
  pointer-events: none;
}

.buttons__button.sound svg {
  opacity: 0;
}

.buttons__button.sound.mute svg:nth-child(1) {
  opacity: 1;
}

.buttons__button.sound.mute:hover svg:nth-child(1) {
  opacity: 0;
}

.buttons__button.sound.mute:hover svg:nth-child(2) {
  opacity: 1;
}

.buttons__button.sound:not(.mute) svg:nth-child(1), .buttons__button.sound:not(.mute) svg:nth-child(2) {
  opacity: 0;
}

.buttons__button.sound:not(.mute) svg:nth-child(3) {
  opacity: 1;
}

.buttons__button.sound:not(.mute):hover svg:nth-child(3) {
  opacity: 0;
}

.buttons__button.sound:not(.mute):hover svg:nth-child(4) {
  opacity: 1;
}

@media only screen and (min-width: 810px) {
  .buttons__button:active {
    transform: scale(1.4);
  }
}

.buttons__button > svg {
  width: 35%;
  height: auto;
  will-change: opacity;
  padding: 1px;
  transition: opacity .3s ease-out;
  position: absolute;
}

.buttons__button > svg:nth-child(2) {
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  opacity: 0;
  margin: 0 .75rem;
}

@media screen and (min-width: 743px) {
  .buttons__button:hover svg:nth-child(1) {
    opacity: 0;
  }

  .buttons__button:hover svg:nth-child(2) {
    opacity: 1;
  }
}

.buttons__switch {
  -webkit-tap-highlight-color: transparent;
  width: 20%;
  height: auto;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  flex-shrink: 0;
  margin: 0 .75rem;
  transition: transform .25s ease-out;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 810px) {
  .buttons__switch:hover, .buttons__switch:active {
    transform: scale(1.2);
  }
}

.buttons__switch-img {
  --scale: 1;
  transform: scale(var(--scale));
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
  -webkit-touch-callout: none;
  pointer-events: none;
  object-fit: contain;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.buttons__switch-img.visible {
  display: block;
}

.outer-fill-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.videos__wrapper {
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.videos__wrapper.visible {
  opacity: 1;
}

img[src="null"] {
  display: none;
}

img[src*="-circle"] {
  border-radius: 100%;
}

body.bg-loaded .outer-fill-bg, body.bg-loaded.cta-confirmed .outer-fill-bg {
  opacity: 1;
  transition: opacity .35s ease-out .3s;
}

body.inner-bg-loaded.cta-confirmed .phone__bg {
  opacity: 1;
  transition: opacity .8s ease-out .3s;
}

.wrapper {
  opacity: 0;
  font-size: 0;
  transition: opacity 2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.wrapper div {
  cursor: pointer;
}

.wrapper.cells-hidden > :not(video) {
  opacity: 0;
}

body.cta-confirmed .wrapper {
  opacity: 1;
}

.loading {
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: #000;
  justify-content: center;
  align-items: center;
  transition: opacity .6s ease-in-out 2s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading__logo {
  width: 72%;
  max-width: 30rem;
  object-fit: contain;
  z-index: 999;
  opacity: 0;
  margin-bottom: 2.125rem;
  transition: opacity .5s ease-in-out 2s, transform 2s ease-in .3s;
}

@media (orientation: portrait) and (max-width: 743px) {
  .loading__logo {
    margin-bottom: 1.7rem;
  }
}

.loading__logo:not(.visible) {
  display: none;
}

body.loop-loaded .loading__logo.visible {
  opacity: 1;
  transition: opacity .5s ease-in-out, transform 2s ease-in .3s;
}

body.cta-confirmed .loading__logo.visible {
  opacity: 0;
  transition: opacity 1.2s ease-in-out .3s, transform 1.2s ease-in .3s;
  transform: rotate(120deg)scale(.5);
}

.loading__inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.loader {
  width: 50%;
  height: 5px;
  z-index: 0;
  --progress: 0;
  opacity: 0;
  background: rgba(255, 255, 255, .2);
  border-radius: 11px;
  flex-shrink: 0;
  transition: opacity .2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

body.loop-loaded .loader {
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

body.loaded-ready .loader {
  opacity: 0;
  transition: opacity .2s ease-in-out;
}

.loader__inner {
  width: 100%;
  height: 100%;
  content: "";
  transform: scaleX(var(--progress));
  transform-origin: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, .5), #fff);
  transition: transform .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

body.cta-confirmed .loader {
  opacity: 0;
}

.static {
  width: 100%;
  height: 100%;
  visibility: hidden;
  will-change: visibility;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.static video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.static img {
  width: 80%;
  height: auto;
  max-width: 18rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signup__submit {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  color: inherit;
  text-transform: uppercase;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  width: 5ch;
  text-align: right;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;
  margin-left: auto;
  padding: 0;
  font-weight: normal;
}

.signup__submit:disabled {
  cursor: not-allowed;
}

.signup {
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  flex-grow: 1;
  gap: .75rem;
  padding: 0 8%;
  font-family: Injurial;
  font-size: max(.85rem, min(2.1vh, 1.2rem));
  line-height: 1.5em;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (orientation: landscape) and (max-width: 1440px) {
  .signup {
    font-size: 1rem;
  }
}

@media (orientation: portrait) and (max-width: 743px) {
  .signup {
    font-size: 1.25rem;
  }
}

.signup__close {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}

.signup__close * {
  pointer-events: none;
}

.signup__input-wrapper {
  flex-grow: 1;
  gap: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@keyframes background-position {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -400% 0;
  }
}

.signup__input-wrapper:before {
  content: "";
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, #f0f 0%, #0f0 25%, #5794f7 50%, #9747ff 75%, #f0f 100%) 0 0 / 400% repeat-x;
  animation: background-position 2s linear infinite;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.signup__input {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 100%;
  text-transform: uppercase;
  color: #fff;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  outline: none;
  flex-grow: 1;
  padding: 0;
  font-family: Injurial;
  font-size: max(.85rem, min(2.1vh, 1.2rem));
  line-height: 1.5em;
  -webkit-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

@media (orientation: landscape) and (max-width: 1440px) {
  .signup__input {
    font-size: 1rem;
  }
}

@media (orientation: portrait) and (max-width: 743px) {
  .signup__input {
    font-size: 1.25rem;
  }
}

.signup__input::-ms-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

.signup__input::placeholder {
  color: rgba(255, 255, 255, .6);
}

.signup__value {
  opacity: 0;
  text-transform: uppercase;
  color: #fff;
  visibility: hidden;
  pointer-events: none;
  font-family: Injurial;
  font-size: max(.85rem, min(2.1vh, 1.2rem));
  line-height: 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

@media (orientation: landscape) and (max-width: 1440px) {
  .signup__value {
    font-size: 1rem;
  }
}

@media (orientation: portrait) and (max-width: 743px) {
  .signup__value {
    font-size: 1.25rem;
  }
}

.signup__check-email, .signup__error {
  text-transform: uppercase;
  color: #fff;
  font-family: Injurial;
  font-size: max(.85rem, min(2.1vh, 1.2rem));
  line-height: 1.5em;
  font-size: inherit;
  border: 1px solid rgba(0, 0, 0, 0);
  display: none;
}

@media (orientation: landscape) and (max-width: 1440px) {
  .signup__check-email, .signup__error {
    font-size: 1rem;
  }
}

@media (orientation: portrait) and (max-width: 743px) {
  .signup__check-email, .signup__error {
    font-size: 1.25rem;
  }
}

.signup--success .signup__check-email, .signup--error .signup__error {
  display: block;
}

.signup--success .signup__input, .signup--error .signup__input {
  display: none;
}

.phone__footer--signup .signup {
  opacity: 1;
  pointer-events: all;
  padding-bottom: var(--footer-bottom);
}

@keyframes bell {
  0% {
    fill: #f0f;
  }

  20% {
    fill: #00fff0;
  }

  40% {
    fill: #5794f7;
  }

  60% {
    fill: #9747ff;
  }

  80% {
    fill: #0f0;
  }
}

.bell-notification {
  animation: bell 5s ease-out infinite;
}

.outer-fill-bg-gif {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

canvas {
  z-index: 9999;
  border: 2px solid green;
  position: fixed;
  top: 0;
  left: 0;
}

body.loaded-ready .permissions__buttons {
  opacity: 1;
  transition-delay: 0s;
  transform: translate(-50%, -50%);
}

.loading-elements {
  width: 100%;
  min-height: 120px;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
  display: flex;
  position: relative;
  overflow: hidden;
}

body.cta-confirmed .permissions__buttons {
  opacity: 0;
  transform: translate(-50%, 100%);
}

.permissions__buttons {
  color: #fff;
  opacity: 0;
  transition: opacity 1s cubic-bezier(.24, .78, .35, 1.09) .1s, transform 1.5s cubic-bezier(.24, .78, .35, 1.09) .1s;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
}

.permissions__buttons > * + * {
  margin-left: 10px;
}

.permissions__buttons span {
  display: block;
}

.permissions__buttons button {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  box-sizing: border-box;
  color: #fff;
  font-variant-numeric: tabular-nums;
  cursor: pointer;
  background-color: #000;
  border: 1px solid #474747;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  font-weight: 300;
  transition: all .5s ease-in-out;
  display: flex;
  box-shadow: 0 0 40px rgba(255, 255, 255, .15);
}

.permissions__buttons button svg {
  pointer-events: none;
  stroke-width: 0;
  margin-right: 5px;
  transition: all .5s ease-in-out;
  fill: #fff !important;
}

@media only screen and (min-width: 1920px) {
  .permissions__buttons button svg {
    stroke: #fff;
    stroke-width: .5px;
  }
}

.permissions__buttons button span {
  pointer-events: none;
}

@media only screen and (max-width: 743px) {
  .permissions__buttons button {
    cursor: none;
  }
}

.permissions__buttons button:hover#audio-off {
  color: #ff005c;
  background-color: rgba(255, 0, 92, .1);
  border-color: #ff005c;
  box-shadow: 0 0 40px rgba(255, 0, 92, .33);
}

.permissions__buttons button:hover#audio-off svg {
  fill: #ff005c !important;
  stroke: #ff005c !important;
}

.permissions__buttons button:hover#audio-on {
  color: #05f272;
  background-color: rgba(5, 242, 114, .1);
  border-color: #05f272;
  box-shadow: 0 0 40px rgba(5, 242, 114, .33);
}

.permissions__buttons button:hover#audio-on svg {
  fill: #05f272 !important;
  stroke: #05f272 !important;
}

.permissions__buttons button:active, .permissions__buttons button:focus, .permissions__buttons button:active svg, .permissions__buttons button:focus svg {
  transition: all ease-in-out;
}

body.custom-bg .phone__inner {
  background: linear-gradient(rgba(38, 145, 0, .5) 0%, rgba(132, 238, 255, .5) 100%);
}

@media only screen and (max-width: 743px) {
  body.custom-bg .outer-fill-bg {
    opacity: 0 !important;
  }

  body.custom-bg .wrapper {
    background-color: #fff;
  }

  body.custom-bg .wrapper:before {
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(38, 145, 0, .5) 0%, rgba(132, 238, 255, .5) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }

  body.custom-bg .phone__inner {
    background: none;
  }
}

video {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

video:host {
  display: none !important;
}

video::-webkit-media-controls-container {
  display: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/*# sourceMappingURL=index.445c43a6.css.map */
